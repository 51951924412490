<template>
    <div class="page bg-white" id="registrationMethod">
        <el-row>
            <el-col :span="12" class="m_b2">
                <el-input class="m_r1"
                          placeholder="请输入证件类型名称"
                          size="small"
                          clearable
                          v-model="srhStr" maxlength="20" style="width: 50%;">
                </el-input>
                <el-button size="small" @click="datalist(1)" type="primary" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="12" class="text_right">
                <el-button v-if="hasPermission('credentialManagement:delete')" type="danger" size="small"
                           icon="el-icon-delete"
                           @click="deleteaddRole()"
                           :disabled="dataListSelections.length <= 0" plain>删除
                </el-button>
                <el-button size="small" v-if="hasPermission('credentialManagement:add')" @click="addaddRole(0)"
                           type="primary">新增
                </el-button>
            </el-col>
        </el-row>
        <el-table
                :data="tableData"
                v-loading="loading"
                @selection-change="selectionChangeHandle"
                size="small"
                height="calc(100vh - 240px)"
                style="width: 100%">
            <el-table-column
                    type="selection"
                    width="50" :selectable="checkSelectable">
            </el-table-column>
            <el-table-column
                    prop="cardTypeName"
                    label="证件名称"
                    show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                    prop="cardNumber"
                    label="证件号位数"
                    width="180">
            </el-table-column>
            <el-table-column prop="cardOff" label="状态" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.cardOff"
                               :disabled="scope.row.cardTypeName == '身份证'"
                               @change="statusChange(scope.row)"
                               :active-value="'0'"
                               :inactive-value="'1'"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column
                    prop="createUser"
                    label="添加人"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    label="添加时间"
                    show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="最后修改时间"
                    show-overflow-tooltip width="200">
            </el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button
                            v-if="hasPermission('credentialManagement:edit')"
                            :disabled="scope.row.cardTypeName == '身份证'"
                            icon="el-icon-edit" type="text"
                            size="mini" @click="addaddRole(1,scope.row)">编辑
                    </el-button>
                    <el-button
                            v-if="hasPermission('credentialManagement:delete')"
                            :disabled="scope.row.cardTypeName == '身份证'"
                            icon="el-icon-delete" type="text"
                            size="mini" @click="deleteaddRole([scope.row.id])">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--        新增、编辑证件类型-->
        <el-dialog
                :title="titile"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <el-form :model="ruleForm" size="small" :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="证件名称" prop="name">
                    <el-input style="width: 80%" placeholder="请输入证件名称" v-model="ruleForm.name"
                              maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="证件号位数" prop="num">
                    <el-input style="width: 80%" placeholder="请输入证件号位数" v-model.trim="ruleForm.num"
                              maxlength="2"></el-input>
                    <span class="marLeft">位</span>
                </el-form-item>
                <el-form-item label="是否显示" prop="switch">
                    <el-switch v-model="ruleForm.switch" :active-value="'0'"
                               :inactive-value="'1'"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
                <el-button size="small" @click="handleClose()">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "registrationMethod",
        data() {
            return {
                srhStr: '',
                tableData: [1],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                titile: '',//弹窗标题
                dialogVisible: false,
                ruleForm: {
                    name: "",
                    num: '',
                    switch: '1',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入证件名称', trigger: 'blur'},
                    ],
                    num: [
                        {required: true, message: '请输入证件号位数', trigger: 'blur'},
                        {pattern: /^[1-9][0-9]*$/, message: '证件号必须为整数'}
                    ],
                },
                id: '',
            }
        },
        mounted() {
            this.datalist(1);
        },
        methods: {
            //请求表中数据
            datalist(type) {
                if (type == 1) {
                    this.pageNo = 1
                }
                this.loading = true;
                this.$axios(this.api.allCommon.list, {
                    current: this.pageNo,
                    size: this.pageSize,
                    cardName: this.srhStr,
                }, 'get').then((res) => {
                    this.loading = false;
                    if (res.status) {
                        this.tableData = res.data.records;
                        if(this.tableData.length == 0 && this.pageNo > 1) {
                            this.pageNo--
                            this.datalist()
                        }
                        this.total = parseInt(res.data.total);
                    }
                })
            },

            // 重置
            resetSearch() {
                this.srhStr = ''
                this.datalist(1)
            },

            //0新增,1编辑
            addaddRole(num, row) {
                this.dialogVisible = true;
                this.$nextTick(() => {
                    if (num == 0) {
                        this.titile = '新增报名方式';
                        this.$refs.ruleForm.resetFields();
                    } else {
                        this.titile = '编辑报名方式';
                        this.ruleForm.name = row.cardTypeName;
                        this.ruleForm.num = row.cardNumber;
                        this.ruleForm.switch = row.cardOff
                        this.id = row.id;
                    }
                })
            },

            // 禁选
            checkSelectable(row) {
                return row.cardTypeName != '身份证'
            },

            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            //删除
            deleteaddRole(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.allCommon.batchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.datalist();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },

            //是否显示
            statusChange(row) {
                this.$axios(this.api.allCommon.updateById, {
                    cardTypeName: row.cardTypeName,
                    cardNumber: row.cardNumber,
                    cardOff: row.cardOff,
                    id: row.id,
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.datalist();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.datalist();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.datalist();
            },

            //弹窗关闭
            handleClose() {
                this.$refs['ruleForm'].resetFields();
                this.dialogVisible = false;
            },

            //确定
            submitForm(formName) {
                this.ruleForm.name = this.ruleForm.name.trim(); //去除左右两边的空格
                if (!this.ruleForm.name) {
                    this.$message.error('证件名称不能为空')
                    return false
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.titile == '新增报名方式') { //新增
                            this.$axios(this.api.allCommon.save, {
                                cardTypeName: this.ruleForm.name,
                                cardNumber: this.ruleForm.num,
                                cardOff: this.ruleForm.switch,
                            }, 'post').then((res) => {
                                if (res.status) {
                                    this.$message.success(res.msg)
                                    this.datalist();
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else {   //编辑
                            this.$axios(this.api.allCommon.updateById, {
                                cardTypeName: this.ruleForm.name,
                                cardNumber: this.ruleForm.num,
                                cardOff: this.ruleForm.switch,
                                id: this.id,
                            }, 'put').then((res) => {
                                if (res.status) {
                                    this.$message.success(res.msg)
                                    this.datalist();
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                        this.handleClose();
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
